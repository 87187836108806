import { graphql } from 'gatsby'
import PageService from '../../components/page-service'
import Seo from '../../components/seo'
import Service from '../../models/service'

interface ServiceProps {
  data: {
    service: Service
  }
}

const ServiceComponent = ({ data: { service } }: ServiceProps) => (
  <>
    <Seo title={service.name} />
    <PageService service={service} />
  </>
)

export default ServiceComponent

export const query = graphql`
  query ($id: String!) {
    service(id: { eq: $id }) {
      id
      name
      description
    }
  }
`
