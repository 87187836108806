import Service from '../../models/service'
import Callout from '../callout'
import Page, { Header } from '../page'

interface PageServiceProps {
  service: Service
}

const PageService = ({ service }: PageServiceProps) => (
  <>
    <Page container>
      <Header title={service.name} subtitle={service.description} />
    </Page>
    <Callout />
  </>
)

export default PageService
